<template>
    <div>
        <el-dialog
            append-to-body
            title="Форма обратной связи"
            :visible.sync="show"
            width="40%"
            destroy-on-close
            :close-on-click-modal="false"
        >
            <el-row>
                <el-form
                    size="small"
                    label-position="top"
                >
                    <el-form-item label="Причина обращения">
                        <el-select
                            style="width:100%"
                            v-model="reason"
                            placeholder="Выберите причину"
                        >
                            <el-option label="Ошибка" value="Ошибка"></el-option>
                            <el-option label="Обратная связь" value="Обратная связь"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Текст обращения">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 20}"
                            placeholder="Текст обращения"
                            v-model="message">
                        </el-input>
                    </el-form-item>
                </el-form>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
                <el-button
                    type="primary"
                    size="small"
                    :disabled="!message || !reason"
                    :loading="loading"
                    @click="sendFeedback"
                >
                    Отправить
                </el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";

export default {
    name: "feedback-dialog",
    components: {},

    props: {},

    data() {
        return {
            loading: false,
            show: false,

            reason: null,
            message: null,
        }
    },
    mounted() {
        if (this.$refs.wrapper.children) {
            on(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    },
    watch: {},
    methods: {
        showModal() {
            this.show = true;
        },

        sendFeedback(){
            this.loading = true;

            this.$api.user.sendFeedback({
                reason: this.reason,
                message: this.message,
            })
                .then(data => {
                    this.$notify.success({
                        title: 'Отправлено',
                        message: 'Сообщение успешно отправлено'
                    });
                    this.message = null;
                    this.show = false;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

    },
    beforeDestroy() {
        if (this.$refs.wrapper.children) {
            off(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    }
}
</script>

<style>

</style>